import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./home/Home";
import Studio from "./studio/Studio";

const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/studio">
                    <Studio />
                </Route>
            </Switch>
        </Router>
    );
};

export default App;
