import React from "react";
import { PhotoSwipeGallery, PhotoSwipeGalleryItem } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";
import "../home/Home.css";

const options = {};

const items = [
    {
        src: "/1.JPG",
        thumbnail: "/1.JPG",
        w: 4032,
        h: 3024,
    },
    {
        src: "/2.JPG",
        thumbnail: "/2.JPG",
        w: 3419,
        h: 2272,
    },
    {
        src: "/3.JPG",
        thumbnail: "/3.JPG",
        w: 3024,
        h: 4032,
    },
    {
        src: "/4.JPG",
        thumbnail: "/4.JPG",
        w: 2976,
        h: 1984,
    },
    {
        src: "/5.JPG",
        thumbnail: "/5.JPG",
        w: 2976,
        h: 1984,
    },
    {
        src: "/6.JPG",
        thumbnail: "/6.JPG",
        w: 2976,
        h: 1984,
    },
    {
        src: "/7.JPG",
        thumbnail: "/7.JPG",
        w: 2976,
        h: 1984,
    },
];

const Studio = () => {
    const getThumbnailContent = (item: PhotoSwipeGalleryItem) => {
        return <img src={item.thumbnail} alt="studio" />;
    };
    const year = new Date().getFullYear();

    return (
        <div className="home">
            <h1>Tom Meyer Art</h1>
            <p>
                <a href="mailto:tommeyerart@gmail.com">tommeyerart@gmail.com</a>{" "}
                | <a href="/">Gallery</a>
            </p>
            <p style={{ width: "40%", minWidth: "300px", margin: "0 auto" }}>
                <p style={{ textAlign: "center" }}>
                    Tom creates his work in his Pembroke, MA studio. Below are
                    some shots from the studio taken in winter 2020.
                </p>
            </p>
            <PhotoSwipeGallery
                items={items}
                options={options}
                thumbnailContent={getThumbnailContent}
                className="gallery"
            />
            <footer>
                {`Tom Meyer & Charlie Meyer ${year}`}
                <br />
            </footer>
        </div>
    );
};

export default Studio;
