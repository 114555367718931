import React, { useEffect, useState } from "react";
import { PhotoSwipeGallery, PhotoSwipeGalleryItem } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";
import "./Home.css";

interface Painting {
    dim: string;
    media: string;
    y: number;
    x: number;
    nfs: string;
    year: string;
    color: string;
}

const Home = () => {
    const getThumbnailContent = (item: PhotoSwipeGalleryItem) => {
        return <img src={item.thumbnail} alt="painting" />;
    };
    const jsonPath = "../artinfo.json";
    const [allPaintings, setAllPaintings] = useState<
        Array<PhotoSwipeGalleryItem & Painting>
    >([]);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [yearFilter, setYearFilter] = useState<string>("");
    const [colorFilter, setColorFilter] = useState<string>("");
    const [mediaFilter, setMediaFilter] = useState<string>("");
    const [numberFilter, setNumberFilter] = useState<string>("");

    useEffect(() => {
        const paintingsJson = require("../artinfo.json") as {
            order: string[];
            paintings: Record<string, Painting>;
        };
        setAllPaintings(
            paintingsJson.order.map((id) => {
                const painting = paintingsJson.paintings[id];
                return {
                    ...painting,
                    thumbnail: `/thumbs/${id}.jpg`,
                    src: `/imgs/${id}.jpg`,
                    pid: id,
                    title: `${painting.media}<br>${painting.dim}<br>${
                        painting.year
                    }<br>painting #${id}${painting.nfs ? "<br>NA" : ""}`,
                };
            })
        );
    }, [jsonPath]);

    const yearSet = new Set<string>();

    allPaintings.forEach((p) => {
        yearSet.add(p.year);
    });
    const yearOptions = Array.from(yearSet.keys()).sort((a, b) =>
        a < b ? 1 : -1
    );

    const year = new Date().getFullYear();

    const paintings = allPaintings.filter((p) => {
        if (yearFilter && p.year !== yearFilter) return false;
        if (colorFilter && p.color !== colorFilter) return false;
        if (mediaFilter && !p.media.includes(mediaFilter)) return false;
        if (
            numberFilter &&
            !(p.pid as string | undefined)?.includes(numberFilter)
        )
            return false;
        return true;
    });
    return (
        <div className="home">
            <h2>Tom Meyer Art</h2>
            <div className="header">
                <p>
                    <a href="mailto:tommeyerart@gmail.com">
                        tommeyerart@gmail.com
                    </a>{" "}
                    | <a href="/studio">About</a>
                </p>
            </div>
            <img className="studio" src="studio.jpg" alt="studio"></img>
            <div className="gallery-header">
                <div>Click images below to expand.</div>
                <div>
                    <i>Items marked NA are not available for purchase.</i>
                </div>
                <div
                    className="filter-button"
                    onClick={() => {
                        setYearFilter("");
                        setMediaFilter("");
                        setColorFilter("");
                        setShowFilters(!showFilters);
                    }}
                >
                    {showFilters ? "Hide Filters" : "Show Filters"}
                </div>
            </div>
            {showFilters && (
                <div className="filters">
                    <div>
                        Year:{" "}
                        <select
                            value={yearFilter}
                            onChange={(e) =>
                                setYearFilter(e.currentTarget.value)
                            }
                        >
                            <option key="start" value="">
                                Any
                            </option>
                            {yearOptions.map((y) => (
                                <option key={y} value={y}>
                                    {y}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        Color:{" "}
                        <select
                            value={colorFilter}
                            onChange={(e) =>
                                setColorFilter(e.currentTarget.value)
                            }
                        >
                            <option key="start" value="">
                                Any
                            </option>
                            <option key="color" value="color">
                                Color
                            </option>
                            <option key="bw" value="bw">
                                B&amp;W
                            </option>
                        </select>
                    </div>
                    <div>
                        Media:{" "}
                        <select
                            value={mediaFilter}
                            onChange={(e) =>
                                setMediaFilter(e.currentTarget.value)
                            }
                        >
                            <option key="start" value="">
                                Any
                            </option>
                            <option key="color" value="acrylic">
                                Acrylic
                            </option>
                            <option key="collage" value="collage">
                                Collage
                            </option>
                            <option key="ink" value="ink">
                                Ink
                            </option>
                            <option key="mixed media" value="mixed media">
                                Mixed Media
                            </option>
                        </select>
                    </div>
                    <div>
                        Painting #:{" "}
                        <input
                            type="text"
                            placeholder="painting number"
                            onChange={(e) =>
                                setNumberFilter(e.currentTarget.value)
                            }
                        />
                    </div>
                </div>
            )}

            {paintings.length > 0 ? (
                <PhotoSwipeGallery
                    items={paintings}
                    thumbnailContent={getThumbnailContent}
                    className="gallery"
                />
            ) : (
                <div style={{ marginTop: "20px" }}>
                    No paintings match this filter
                </div>
            )}
            <footer>
                Tom Meyer &amp; Charlie Meyer {year}
                <br />
            </footer>
        </div>
    );
};

export default Home;
